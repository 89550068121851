import './index.css';
import React, { useRef, useState, useEffect} from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { Row, Column, Stack, StackItem, Axis, ContentContainer } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, A, Caption } from './TypographyComponents';
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import uuid from 'react-uuid';
import _ from 'lodash';
import { Color } from './flatColors';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Pagination from '@mui/material/Pagination';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import IconButton from '@mui/material/IconButton';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { 
  getReportData,
  getRefs,
  getIncomeData,
  getIncomeDataFromTo,
  resetState,
} from "./redux/actions";


function IncomePage() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;

  return (
    <ThemeProvider theme={stateObj.theme} >
      <ContentContainer>
        <Body></Body>
      </ContentContainer>
    </ThemeProvider>
  );
}

function Body() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const theme = stateObj.theme;
  const navigate = useNavigate();
  const lt = stateObj.lt;
  const ref = useRef();
  const [divRef, setDivRef] = useState(null);
  let doit;

  useEffect(()=>{
    setDivRef(ref.current);
  },[]);


  const callback = ()=> {
    if (divRef === null) {
      return;
    }
    clearTimeout(doit);
    doit = setTimeout(function() {
      let height = divRef.clientHeight;  
      window.top.postMessage({resize: height, table:"income"}, '*');
    }, 100);
  }

  return(
    <div width="100%" ref={ref}>
      <Column width="100%" main={Axis.main.start} color='rgb(28,28,28)'>
        <Column width='calc(100%)'>
          <DataTable callback={callback} title='ИСТОРИЯ ЗАКАЗОВ'></DataTable>
        </Column>
      </Column>
    </div>
  );
}


function DataTable(props) {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const theme = stateObj.theme;
  const query = new URLSearchParams(window.location.search);
  let user = query.get('user');
  const lt = stateObj.lt;
  const [page, setPage] = React.useState(1);
  let refsData;
  const openedRefsData = stateObj.openedRefsData;
  const gonnaBePaidRefsData = stateObj.gonnaBePaidRefsData;
  const closedRefsData = stateObj.closedRefsData;
  const incomeData = stateObj.incomeData;

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = today.getFullYear();
  let currentMonthFrom = mm + '-' + '01' + '-' + yyyy;
  let currentMonthTo = mm + '-' + dayjs(currentMonthFrom).daysInMonth() + '-' + yyyy;

  const [valueDateFrom, setValueDateFrom] = React.useState(dayjs(currentMonthFrom));
  const [valueDateTo, setValueDateTo] = React.useState(dayjs(currentMonthTo));
  const [mainHeight, setMainHeight] = React.useState(250);

  //user = 'info@vessna.io'

  useEffect(()=>{
    //let ; //pangolizard@gmail.com //info@vessna.io
    //console.log(user);
    let date = new Date(valueDateFrom);
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();
    let dt = mm + '-' + dd + '-' + yyyy;
    let fromDate = yyyy+'-'+mm+'-'+dd+"T00:00:00";
    date = new Date(valueDateTo);
    dd = String(date.getDate()).padStart(2, '0');
    mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    yyyy = date.getFullYear();
    dt = mm + '-' + dd + '-' + yyyy;
    let toDate = yyyy+'-'+mm+'-'+dd+"T23:59:59";
    dispatch(getIncomeDataFromTo(user, fromDate, toDate));
  },[page]);

  props.callback();

  //console.log(incomeData);


  const createData = (title, dt, amount, income, refCode, user) => {
    return { title, dt, amount, income, refCode, user };
  }

  //console.log(reportData);

  let rows = [];
  let totalAmount = 0;
  let totalIncome = 0;
  if (incomeData && _.isEmpty(incomeData.deals) === false) {
    incomeData.deals.map((item)=>{
      //let date = new Date(item.CLOSEDATE.substring(0, item.CLOSEDATE.indexOf("T")));
      //let formattedDate = `${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`;
      totalAmount+=parseFloat(item.OPPORTUNITY);
      totalIncome+=parseFloat(item.UF_CRM_1665751767);
      let formattedDate = item.CLOSEDATE.substring(8,10)+"."+item.CLOSEDATE.substring(5,7)+"."+item.CLOSEDATE.substring(0,4);
      return(rows.push(createData(item.TITLE, formattedDate, (parseFloat(item.OPPORTUNITY)).toLocaleString(undefined, { minimumFractionDigits: 2 }), item.UF_CRM_1665751767, item.UF_CRM_636CEBC2341DE, item.UF_CRM_1664200033)));
    });
  }

  //console.log(rows);
  const handleDatePickerFrom = (newValue) => {
    setValueDateFrom(dayjs(`${newValue.$y}-${newValue.$M+1}-${newValue.$D}`));
  }

  const handleDatePickerTo = (newValue) => {
    setValueDateTo(dayjs(`${newValue.$y}-${newValue.$M+1}-${newValue.$D}`));
  }

  const handleRun = ()=>{
    let date = new Date(valueDateFrom);
    let dd = String(date.getDate()).padStart(2, '0');
    let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = date.getFullYear();
    let dt = mm + '-' + dd + '-' + yyyy;
    let fromDate = yyyy+'-'+mm+'-'+dd+"T00:00:00";
    date = new Date(valueDateTo);
    dd = String(date.getDate()).padStart(2, '0');
    mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    yyyy = date.getFullYear();
    dt = mm + '-' + dd + '-' + yyyy;
    let toDate = yyyy+'-'+mm+'-'+dd+"T23:59:59";
    //console.log(user, props.status, fromDate, toDate);
    dispatch(getIncomeDataFromTo(user, fromDate, toDate));
  }


  return (
    <>
    <Column >      
        <Column top={20} bottom={15} sx={{minHeight:mainHeight}}>
            <Row cross={Axis.cross.center} bottom={20} top={40}>
                <Row left={0}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ru'}>
                    <DesktopDatePicker
                      label="C"
                      value={valueDateFrom}
                      onChange={handleDatePickerFrom}
                      onOpen={()=>{
                        setMainHeight(470);
                      }}
                      onClose={()=>{
                        setMainHeight(250);
                      }}
                      sx={{svg:{color:"white"}, '& .MuiInputBase-root': {color:"white"}, label:{color:"#DCDCDC"}, '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                          borderColor: "#DCDCDC",
                          },
                          '&:hover fieldset': {
                          borderColor: "#dbae3b",
                          },
                          '&.Mui-focused fieldset': {
                          borderColor: "#dbae3b",
                          },
                      },}}
                      renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
                </Row>
                <Row left={20}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ru'}>
                    <DesktopDatePicker
                    label="По"
                    value={valueDateTo}
                    onChange={handleDatePickerTo}
                    onOpen={()=>{
                      setMainHeight(470);
                    }}
                    onClose={()=>{
                      setMainHeight(250);
                    }}
                    sx={{svg:{color:"white"}, '& .MuiInputBase-root': {color:"white"}, label:{color:"#DCDCDC"}, '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                        borderColor: "#DCDCDC",
                        },
                        '&:hover fieldset': {
                        borderColor: "#dbae3b",
                        },
                        '&.Mui-focused fieldset': {
                        borderColor: "#dbae3b",
                        },
                    },}}
                    renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
                </Row>
                <Row left={20}>
                <IconButton color="primary" component="label" onClick={handleRun}>
                <PlayCircleIcon />
                </IconButton>
                </Row>
            </Row>
            <CustomizedTables rows={rows} totalAmount={totalAmount.toLocaleString(undefined, { minimumFractionDigits: 2 })} totalIncome={totalIncome.toLocaleString(undefined, { minimumFractionDigits: 2 })}></CustomizedTables>
        </Column>
    </Column>
    </>
  );
}

const MyPagination = styled(Pagination)(({ theme }) => ({
  [`& .MuiPaginationItem-root`]: {
    color: Color.flatWhite,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'rgb(0,0,0)',
    color: Color.flatWhite,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: Color.flatWhite,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '& td,th': {
    borderColor:'rgba(74,74,74,1)'
  }, 
  '&:nth-of-type(odd)': {
    backgroundColor: 'rgba(56,56,56,1)',
  },
  '&:nth-of-type(even)': {
    backgroundColor: 'rgba(36, 36, 36, 1)',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));




function CustomizedTables(props) {
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  return (
    <>
    <div style={{display: lt.max <= 8 ? 'block': 'none'}}>
    {props.rows.map((row) => (
      <Column width='100%' top={15} bottom={15} key={uuid()}>
        <Row top={0} bottom={20} width='100%' height={1} color={Color.flatWhite}></Row>
        <Row main={Axis.main.between} top={20}>
          <Row><Subtitle2 sx={{color: Color.flatWhiteDark}}>Заказ</Subtitle2></Row>
          <Row><Subtitle2 sx={{color: Color.flatWhite}}>{row.title}</Subtitle2></Row>
        </Row>
        <Row main={Axis.main.between} top={7}>
          <Row><Subtitle2 sx={{color: Color.flatWhiteDark}}>Дата покупки</Subtitle2></Row>
          <Row><Subtitle2 sx={{color: Color.flatWhite}}>{row.dt}</Subtitle2></Row>
        </Row>
        <Row main={Axis.main.between} top={7}>
          <Row><Subtitle2 sx={{color: Color.flatWhiteDark}}>Сумма покупки</Subtitle2></Row>
          <Row><Subtitle2 sx={{color: Color.flatWhite}}>{row.amount}</Subtitle2></Row>
        </Row>
        <Row main={Axis.main.between} top={7}>
          <Row><Subtitle2 sx={{color: Color.flatWhiteDark}}>Реферальный доход</Subtitle2></Row>
          <Row><Subtitle2 sx={{color: Color.flatWhite}}>{row.income}</Subtitle2></Row>
        </Row>
        <Row main={Axis.main.between} top={7}>
          <Row><Subtitle2 sx={{color: Color.flatWhiteDark}}>Промокод</Subtitle2></Row>
          <Row><Subtitle2 sx={{color: Color.flatWhite}}>{row.refCode}</Subtitle2></Row>
        </Row>
      </Column>
    ))}
    </div>
    <div style={{display: lt.max <= 8 ? 'none': 'block'}}>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">Заказ</StyledTableCell>
            <StyledTableCell align="left">Дата покупки</StyledTableCell>
            <StyledTableCell align="right">Сумма покупки</StyledTableCell>
            <StyledTableCell align="right">Реферальный доход</StyledTableCell>
            <StyledTableCell align="right">Промокод</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row) => (
            <StyledTableRow key={uuid()}>
              <StyledTableCell align="left">{row.title}</StyledTableCell>
              <StyledTableCell align="left">{row.dt}</StyledTableCell>
              <StyledTableCell align="right">{row.amount}</StyledTableCell>
              <StyledTableCell align="right">{row.income}</StyledTableCell>
              <StyledTableCell align="right">{row.refCode}</StyledTableCell>
            </StyledTableRow>
          ))}
          <StyledTableRow key={uuid()}>
            <StyledTableCell align="left"></StyledTableCell>
            <StyledTableCell align="left"></StyledTableCell>
            <StyledTableCell align="right">{props.totalAmount}</StyledTableCell>
            <StyledTableCell align="right">{props.totalIncome}</StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
        </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
    </div>
    </>
  );
}

export default IncomePage;