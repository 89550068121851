import './index.css';
import React, { useRef, useState, useEffect} from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { Row, Column, Stack, StackItem, Axis, ContentContainer } from './layout'; 
import { Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Body1, Body2, A, Caption } from './TypographyComponents';
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import uuid from 'react-uuid';
import _ from 'lodash';
import { Color } from './flatColors';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Pagination from '@mui/material/Pagination';
import { 
  getReportData,
  getRefs,
  resetState,
} from "./redux/actions";


function ReferalsPage() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;

  return (
    <ThemeProvider theme={stateObj.theme} >
      <ContentContainer>
        <Body></Body>
      </ContentContainer>
    </ThemeProvider>
  );
}

function Body() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const theme = stateObj.theme;
  const navigate = useNavigate();
  const lt = stateObj.lt;
  const ref = useRef();
  const [divRef, setDivRef] = useState(null);
  let doit;

  useEffect(()=>{
    setDivRef(ref.current);
  },[]);

  const callback = ()=> {
    if (divRef === null) {
      return;
    }
    clearTimeout(doit);
    doit = setTimeout(function() {
      let height = divRef.clientHeight;  
      window.top.postMessage({resize: height, table:"referals"}, '*');
    }, 100);
  }

  return(
    <div width="100%" ref={ref}>
      <Column width="100%" center color='rgb(28,28,28)'>
        <Column width='calc(100%)'>
          <DataTable callback={callback} status={0} title='ФОРМИРУЮТСЯ'></DataTable>
          <DataTable callback={callback} status={1} title='НА ВЫПЛАТЕ'></DataTable>
          <DataTable callback={callback} status={2} title='ЗАВЕРШЕННЫЕ'></DataTable>
        </Column>
      </Column>
    </div>
  );
}


function DataTable(props) {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const theme = stateObj.theme;
  const query = new URLSearchParams(window.location.search);
  const user = query.get('user');
  const lt = stateObj.lt;
  const [page, setPage] = React.useState(1);
  let refsData;
  const openedRefsData = stateObj.openedRefsData;
  const gonnaBePaidRefsData = stateObj.gonnaBePaidRefsData;
  const closedRefsData = stateObj.closedRefsData;

  useEffect(()=>{
    //let user = 'info@vessna.io';
    //console.log(user);
    dispatch(getRefs(user, props.status, page));
    props.callback();
  },[page]);

  props.callback();
  
  if (props.status === 0) {
    refsData = openedRefsData;
  } else if (props.status === 1) {
    refsData = gonnaBePaidRefsData;
  } else if (props.status === 2) {
    refsData = closedRefsData;
  }

  const createData = (year, month, dt, sts, amount) => {
    return { year, month, dt, sts, amount };
  }

  //console.log(reportData);

  let rows = [];
  if (refsData && _.isEmpty(refsData.refs) === false) {
    let temp = _.flattenDeep(refsData.products);
    refsData.refs.map((item)=>{
      let date = new Date(item.CLOSEDATE.substring(0, item.CLOSEDATE.indexOf("T")));
      let formattedDate = `${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`;
      let n = _.findIndex(refsData.stages, (o) => o.hasOwnProperty(item.STAGE_ID) === true);
      let st;
      if (n !== -1) {
        st = refsData.stages[n][item.STAGE_ID];
      }
      return(rows.push(createData(item.UF_CRM_1665755074963, item.UF_CRM_1665755085810, formattedDate, st, (parseFloat(item.OPPORTUNITY)).toLocaleString(undefined, { minimumFractionDigits: 2 }))));
    });
  }
  
  const handlePageChange = (e,p) => { 
    if (page !== p) {
      setPage(p); 
      if (props.status === 0) {
        dispatch(resetState({inProcessDataProcessing:1}));
      } else if (props.status === 1) {
        dispatch(resetState({completedDataProcessing:1}));
      } else if (props.status === 2) {
        dispatch(resetState({cancelledDataProcessing:1}));
      } 
    }
  }


  return (
    <>
    <Column>
      { _.isEmpty(rows) === false &&
        <Column top={20} bottom={15}>
          { refsData.pageInfo.total_pages > 1 ?
          <>
          <Row bottom={10}><Header4 sx={{color:Color.flatWhite}}>{props.title}</Header4></Row>
          <Row self={Axis.self.center} top={10} bottom={20}><MyPagination count={refsData.pageInfo.total_pages} color='secondary' onChange={handlePageChange} sx={{color:'white'}}></MyPagination></Row>          
            <CustomizedTables rows={rows} status={props.status}></CustomizedTables>
          </>
          :
          <>
            <Row bottom={10}><Header4 sx={{color:Color.flatWhite}}>{props.title}</Header4></Row>
            <CustomizedTables rows={rows} status={props.status}></CustomizedTables>
          </>
          }
        </Column>
      }
    </Column>
    </>
  );
}

const MyPagination = styled(Pagination)(({ theme }) => ({
  [`& .MuiPaginationItem-root`]: {
    color: Color.flatWhite,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'rgb(0,0,0)',
    color: Color.flatWhite,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: Color.flatWhite,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '& td,th': {
    borderColor:'rgba(74,74,74,1)'
  }, 
  '&:nth-of-type(odd)': {
    backgroundColor: 'rgba(56,56,56,1)',
  },
  '&:nth-of-type(even)': {
    backgroundColor: 'rgba(36, 36, 36, 1)',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));




function CustomizedTables(props) {
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  return (
    <>
    <div style={{display: lt.max <= 8 ? 'block': 'none'}}>
    {props.rows.map((row) => (
      <Column width='100%' top={15} bottom={15} key={uuid()}>
        <Row top={0} bottom={20} width='100%' height={1} color={Color.flatWhite}></Row>
        <Row main={Axis.main.between} top={20}>
          <Row><Subtitle2 sx={{color: Color.flatWhiteDark}}>Год</Subtitle2></Row>
          <Row><Subtitle2 sx={{color: Color.flatWhite}}>{row.year}</Subtitle2></Row>
        </Row>
        <Row main={Axis.main.between} top={7}>
          <Row><Subtitle2 sx={{color: Color.flatWhiteDark}}>Месяц</Subtitle2></Row>
          <Row><Subtitle2 sx={{color: Color.flatWhite}}>{row.month}</Subtitle2></Row>
        </Row>
        <Row main={Axis.main.between} top={7} bottom={props.status === 2 ? 0 : 10}>
          <Row><Subtitle2 sx={{color: Color.flatWhiteDark}}>Сумма</Subtitle2></Row>
          <Row><Subtitle2 sx={{color: Color.flatWhite}}>{row.amount}</Subtitle2></Row>
        </Row>
        {props.status === 2 &&
        <>
        <Row main={Axis.main.between} top={7} >
          <Row><Subtitle2 sx={{color: Color.flatWhiteDark}}>Дата</Subtitle2></Row>
          <Row><Subtitle2 sx={{color: Color.flatWhite}}>{row.dt}</Subtitle2></Row>
        </Row>
        <Row main={Axis.main.between} top={7} bottom={10}>
          <Row><Subtitle2 sx={{color: Color.flatWhiteDark}}>Статус</Subtitle2></Row>
          <Row><Subtitle2 sx={{color: Color.flatWhite}}>{row.sts}</Subtitle2></Row>
        </Row>
        </>
        }
      </Column>
    ))}
    </div>
    <div style={{display: lt.max <= 8 ? 'none': 'block'}}>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">Год</StyledTableCell>
            <StyledTableCell align="left">Месяц</StyledTableCell>
            {props.status === 2 &&
              <>
              <StyledTableCell align="left">Дата</StyledTableCell>
              <StyledTableCell align="left">Статус</StyledTableCell>
              </>
            }
            <StyledTableCell align="right">Сумма</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row) => (
            <StyledTableRow key={uuid()}>
              <StyledTableCell align="left">{row.year}</StyledTableCell>
              <StyledTableCell align="left">{row.month}</StyledTableCell>
              { props.status === 2 &&
                <>
                <StyledTableCell align="left">{row.dt}</StyledTableCell>
                <StyledTableCell align="left">{row.sts}</StyledTableCell>
                </>
              }
              <StyledTableCell align="right">{row.amount}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
    </>
  );
}

export default ReferalsPage;