import { 
  SET_CALCULATED_LAYOUT, 
  RESET_STATE,
  GET_REPORT_DATA,
  GET_REPORT_DATA_FROM_TO,
  GET_REFS,
  GET_INCOME_DATA,
} from "./types";
import { calc } from '../layout';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    allVariants: {  
      fontFamily: "Inter",
      textTransform: 'none',
      color:"#444"
    }
  },
  palette: {
    primary: {
      main: "#dbae3b",
    },
    secondary: {
      main: "#fff",
    },
    text: {
      main: "#444",
    },
    text2: {
      main: "#bbb",
    },
    text3: {
      main: "#999",
    },
    footer: {
      main: '#fff'
    }
  },
});

const INITIAL_STATE = {
  theme: theme,
  lt: calc(window.$(document).width(), 4),
  //api: "https://acc.goldexrobot.ru/report.php",
  api:"https://goldex.innadepot.com/report.php",
  reportData: { deals:[], products:[]},
  inProcessData: { deals:[], products:[]},
  completedData: { deals:[], products:[]},
  cancelledData: { deals:[], products:[]},
  inProcessDataProcessing:0,
  completedDataProcessing:0,
  cancelledDataProcessing:0,
  openedRefsData: {refs:[], stages:[], pageInfo: {}},
  gonnaBePaidRefsData: {refs:[], stages:[], pageInfo: {}},
  closedRefsData: {refs:[], stages:[], pageInfo: {}},
  incomeData: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CALCULATED_LAYOUT:
      return {
        ...state,
        ...action.payload,
      };
    case GET_REPORT_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case GET_REFS:
      return {
        ...state,
        ...action.payload,
      };
    case GET_INCOME_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_STATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return INITIAL_STATE;
  }
};