import axios from 'axios';
import { parallel, reflect, reflectAll } from 'async';

import { 
  SET_CALCULATED_LAYOUT, 
  RESET_STATE,
  GET_REPORT_DATA, 
  GET_REPORT_DATA_FROM_TO,
  ERR_TYPE_GET_REPORT_DATA,
  GET_INCOME_DATA,
  ERR_TYPE_GET_INCOME_DATA,
  GET_REFS,
} from "./types";

import _ from 'lodash';

let dealsList = [];
let dealGoods = [];

export const getReportData = (userLogin, status, page) => async (dispatch, getState) => {
  const state = getState().mainState;
  let result = await axios({url: state.api,method: 'post',data: { command: "getReportData", userLogin: userLogin, status: status, page: page}});
  //console.log(result.data);
  dealsList = [];
  dealGoods = [];
  let deals = result.data.deals;
  let stages = result.data.stages;
  let pageInfo = {page:result.data.page, total_pages: result.data.total_pages, total: result.data.total};
  let functions = [];
  deals.map((v,i)=>{
    dealsList.push({id: v.ID, taken: false});
    functions.push(getDealGoods);
  });
  parallel(reflectAll(functions), (err, results)=> {
    //console.log(dealGoods);
    let payload = {};
    if (status === 0) {
      payload = {inPocessData:{deals: deals, products:dealGoods, stages: stages, pageInfo:pageInfo}, inProcessDataProcessing:2, completedDataProcessing:state.completedDataProcessing, cancelledDataProcessing:state.cancelledDataProcessing}
    } else if (status === 1) {
      payload = {completedData:{deals:deals, products:dealGoods, stages: stages, pageInfo:pageInfo}, inProcessDataProcessing:state.inProcessDataProcessing, completedDataProcessing:2, cancelledDataProcessing:state.cancelledDataProcessing}
    } else if (status === 2) {
      payload = {cancelledData:{deals:deals, products:dealGoods, stages: stages, pageInfo:pageInfo}, inProcessDataProcessing:state.inProcessDataProcessing, completedDataProcessing:state.completedDataProcessing, cancelledDataProcessing:2}
    }
    dispatch({
      type: GET_REPORT_DATA,
      payload: payload,
    });
  });
  let payload = {};
  if (status === 0) {
    payload = {inPocessData:{deals:deals, products:[], stages: stages, pageInfo:pageInfo}, inProcessDataProcessing:1, completedDataProcessing:state.completedDataProcessing, cancelledDataProcessing:state.cancelledDataProcessing}
  } else if (status === 1) {
    payload = {completedData:{deals:deals, products:[], stages: stages, pageInfo:pageInfo}, inProcessDataProcessing:state.inProcessDataProcessing, completedDataProcessing:1, cancelledDataProcessing:state.cancelledDataProcessing}
  } else if (status === 2) {
    payload = {cancelledData:{deals:deals, products:[], stages: stages, pageInfo:pageInfo}, inProcessDataProcessing:state.inProcessDataProcessing, completedDataProcessing:state.completedDataProcessing, cancelledDataProcessing:1}
  }
  dispatch({
    type: GET_REPORT_DATA,
    payload: payload,
  });
  
}

export const getReportDataFromTo = (userLogin, status, fromDate, toDate) => async (dispatch, getState) => {
  const state = getState().mainState;
  console.log(fromDate, toDate);
  let result = await axios({url: state.api,method: 'post',data: { command: "get_report_data_from_to", user_login: userLogin, status: status, from: fromDate, to: toDate}});
  //console.log(result.data);
  dealsList = [];
  dealGoods = [];
  let deals = result.data.deals;
  let stages = result.data.stages;
  console.log(result.data);
  //let pageInfo = {page:result.data.page, total_pages: result.data.total_pages, total: result.data.total};
  if (_.isEmpty(deals)) {
    let payload = {};
    payload = {inPocessData:{deals:deals, products:[], stages: stages}, inProcessDataProcessing:1, completedDataProcessing:0, cancelledDataProcessing:0}
    /*if (status === 0) {
      payload = {inPocessData:{deals:deals, products:[], stages: stages}, inProcessDataProcessing:1, completedDataProcessing:state.completedDataProcessing, cancelledDataProcessing:state.cancelledDataProcessing}
    } else if (status === 1) {
      payload = {completedData:{deals:deals, products:[], stages: stages}, inProcessDataProcessing:state.inProcessDataProcessing, completedDataProcessing:1, cancelledDataProcessing:state.cancelledDataProcessing}
    } else if (status === 2) {
      payload = {cancelledData:{deals:deals, products:[], stages: stages}, inProcessDataProcessing:state.inProcessDataProcessing, completedDataProcessing:state.completedDataProcessing, cancelledDataProcessing:1}
    }*/
    dispatch({
      type: GET_REPORT_DATA,
      payload: payload,
    });
  } else {
    let functions = [];
    deals.map((v,i)=>{
      dealsList.push({id: v.ID, taken: false});
      functions.push(getDealGoods);
    });
    parallel(reflectAll(functions), (err, results)=> {
      let payload = {};
      if (status === 0) {
        payload = {inPocessData:{deals: deals, products:dealGoods, stages: stages}, inProcessDataProcessing:2, completedDataProcessing:state.completedDataProcessing, cancelledDataProcessing:state.cancelledDataProcessing}
      } else if (status === 1) {
        payload = {completedData:{deals:deals, products:dealGoods, stages: stages}, inProcessDataProcessing:state.inProcessDataProcessing, completedDataProcessing:2, cancelledDataProcessing:state.cancelledDataProcessing}
      } else if (status === 2) {
        payload = {cancelledData:{deals:deals, products:dealGoods, stages: stages}, inProcessDataProcessing:state.inProcessDataProcessing, completedDataProcessing:state.completedDataProcessing, cancelledDataProcessing:2}
      }
      dispatch({
        type: GET_REPORT_DATA,
        payload: payload,
      });
    });
    let payload = {};
    if (status === 0) {
      payload = {inPocessData:{deals:deals, products:[], stages: stages}, inProcessDataProcessing:1, completedDataProcessing:state.completedDataProcessing, cancelledDataProcessing:state.cancelledDataProcessing}
    } else if (status === 1) {
      payload = {completedData:{deals:deals, products:[], stages: stages}, inProcessDataProcessing:state.inProcessDataProcessing, completedDataProcessing:1, cancelledDataProcessing:state.cancelledDataProcessing}
    } else if (status === 2) {
      payload = {cancelledData:{deals:deals, products:[], stages: stages}, inProcessDataProcessing:state.inProcessDataProcessing, completedDataProcessing:state.completedDataProcessing, cancelledDataProcessing:1}
    }
    dispatch({
      type: GET_REPORT_DATA,
      payload: payload,
    });
  }
  
  
}


async function getDealGoods(callback) {
  let i = _.findIndex(dealsList, (o) => o.taken === false);
  let id = 0;
  if (i >= 0) {
    id = dealsList[i].id;
    dealsList[i].taken = true;
  }
  let result = await axios({url: "https://acc.goldexrobot.ru/report.php", method: 'post',data: { command: "get_deal_goods", id: id}});
  dealGoods.push(result.data);
  callback(null);
}

export const getRefs = (userLogin, status, page) => async (dispatch, getState) => {
  //status: 0 - opened, 1 - gonna_be_paid, 2 - closed
  const state = getState().mainState;
  let result = await axios({url: state.api,method: 'post',data: { command: "get_refs", userLogin: userLogin, status: status, page: page}});
  //console.log(result.data);
  let refs = result.data.refs;
  let stages = result.data.stages;
  let pageInfo = {page:result.data.page, total_pages: result.data.total_pages, total: result.data.total};
  let refsInfo = {refs:refs, stages:stages, pageInfo: pageInfo};
  let payload = {};
  if (status === 0) {
    payload = {openedRefsData: refsInfo};
  } else if (status === 1) {
    payload = {gonnaBePaidRefsData: refsInfo};
  } else if (status === 2) {
    payload = {closedRefsData: refsInfo};
  }
  dispatch({
    type: GET_REFS,
    payload: payload,
  });
  
}

export const getIncomeData = (userLogin, page) => async (dispatch, getState) => {
  const state = getState().mainState;
  let result = await axios({url: state.api,method: 'post',data: { command: "get_income_data", userLogin: userLogin, page: page}});
  let pageInfo = {page:result.data.page, total_pages: result.data.total_pages, total: result.data.total};
  let info = {deals:result.data.deals, pageInfo: pageInfo};
  dispatch({
    type: GET_INCOME_DATA,
    payload: {
      incomeData: info
    }
  });
}

export const getIncomeDataFromTo = (userLogin, fromDate, toDate) => async (dispatch, getState) => {
  const state = getState().mainState;
  let result = await axios({url: state.api,method: 'post',data: { command: "get_income_data_from_to", user_login: userLogin, from: fromDate, to: toDate}});
  let info = {deals:result.data.deals};
  if (result.data.deals === undefined) {
    info = {deals:[]};
  }
  dispatch({
    type: GET_INCOME_DATA,
    payload: {
      incomeData: info
    }
  });
}

export const resetState = (payload) => (dispatch, getState) => {
  try {
    dispatch({
      type: RESET_STATE,
      payload: payload,
    });
  } catch (error) {
    console.log("Error", error);
  }
};


export const updateCalculatedLayout = (lt) => (dispatch, getState) => {
  try {
    dispatch({
      type: SET_CALCULATED_LAYOUT,
      payload: {
        lt: lt,
        errMessage: ""
      },
    });
  } catch (error) {
    console.log("Error", error);
  }
};
